import { Layout as BaseLayout } from "antd";
import { FC, Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Footer, Header } from ".";
import SideMenu from "../menu";
import { lightGray1 } from "src/constants/Colors";

const Layout: FC = () => {
  const location = useLocation();
  const showSideMenu = location.pathname !== "/";

  return (
    <BaseLayout style={{display: "flex", flexDirection: 'row'}}>
      {showSideMenu && <SideMenu />}
      <BaseLayout style={{backgroundColor: lightGray1, padding: showSideMenu? 30: 0}}>
      {showSideMenu && <Header />}
        <Suspense>
          <Outlet/>
        </Suspense>
      </BaseLayout>
    </BaseLayout>
  );
};

export { Layout };
