import { FC, lazy } from "react";
import { Outlet, RouteObject } from "react-router-dom";
import DefaultLayout, { Loader } from "../components/layout";
import PrivateRoute from "../components/PrivateRoute";
import { ROUTES } from "src/constants/routes";

const Users = lazy<FC>(() => import("./users"));
const UsersNewGuest = lazy<FC>(() => import("./userRequestsGuest"));
const UsersDetails = lazy<FC>(() => import("./userDetails.tsx"));
const UnregisteredUsers = lazy<FC>(() => import("./unregisteredUsers"));
const Login = lazy<FC>(() => import("./login"));
const Dashboard = lazy<FC>(() => import("./dashboard"));
const NewEvent = lazy<FC>(() => import("./newEvent"));
const EventDetails = lazy<FC>(() => import("./eventDetails"));
const UserManagement = lazy<FC>(() => import("./userManagement"));
const EventManagement = lazy<FC>(() => import("./eventManagement"));
const Subscriptions = lazy<FC>(() => import("./subscriptions"));
const Reports = lazy<FC>(() => import("./reports"));

const routes: RouteObject[] = [
  {
    element: <DefaultLayout />,
    loader: () => <Loader spinning />,
    children: [
      {
        element: <Login />,
        path: "",
      },
      {
        element: <Dashboard />,
        path: ROUTES.DASHBOARD,
        index: true,
      },
      {
        element: <PrivateRoute><Users /></PrivateRoute>,
        path: ROUTES.USERS,
        index: true,
      },
      {
        element: <UsersNewGuest />,
        path: ROUTES.USERS_NEW_GUEST,
        index: true,
      },
      {
        element: <UsersDetails />,
        path: ROUTES.USER_DETAILS,
        index: true,
      },
      {
        element: <UnregisteredUsers />,
        path: ROUTES.UNREGISTERED_USERS,
        index: true,
      },
      {
        element: <NewEvent />,
        path: ROUTES.NEW_EVENT,
        index: true,
      },
      {
        element: <EventDetails />,
        path: ROUTES.EVENT_DETAILS,
        index: true,
      },
      {
        element: <UserManagement />,
        path: ROUTES.USER_MANAGEMENT,
        index: true,
      },
      {
        element: <EventManagement />,
        path: ROUTES.EVENT_MANAGEMENT,
        index: true,
      },
      {
        element: <Subscriptions />,
        path: ROUTES.SUBSCRIPTIONS,
        index: true,
      },
      {
        element: <Reports />,
        path: ROUTES.REPORTS,
        index: true,
      },
      {
        element: <Outlet />,
        path: "*",
      },
    ],
  },
];

export default routes;
