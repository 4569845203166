import { create } from 'zustand';

interface CounterState {
  counterEventReviewed: number;
  setCounterEventReviewed: (value: number) => void;
}

export const useCounterEventReviewed = create<CounterState>((set) => ({
  counterEventReviewed: 0,
  setCounterEventReviewed: (value: number) => set({ counterEventReviewed: value }),
}));
