export const ROUTES = {
  LOGIN: "/",
  DASHBOARD: "/dashboard",
  USERS: "/users",
  USERS_NEW_GUEST: "/usersGuest",
  USER_DETAILS: "/usersDetails",
  UNREGISTERED_USERS: '/unregisteredUsers',
  NEW_EVENT: "/newEvent",
  EVENT_DETAILS: "/eventDetails",
  USER_MANAGEMENT: "/userManagement",
  EVENT_MANAGEMENT: "/eventManagement",
  SUBSCRIPTIONS: "/subscriptions",
  REPORTS: "/reports",
} as const;