import React, { createContext, useContext, useState, ReactNode } from 'react';

interface MenuContextType {
  collapseMenu: boolean;
  toggleCollapseMenu: () => void;
}

const MenuContext = createContext<MenuContextType | undefined>(undefined);

export const MenuProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [collapseMenu, setCollapseMenu] = useState<boolean>(false);

  const toggleCollapseMenu = () => setCollapseMenu(prev => !prev);

  return (
    <MenuContext.Provider value={{ collapseMenu, toggleCollapseMenu }}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = (): MenuContextType => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error('error MenuProvider');
  }
  return context;
};
