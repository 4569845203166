import { create } from 'zustand';

interface CounterState {
  counterUserRequestsGuest: number;
  setCounterUserRequestsGuest: (value: number) => void;
}

export const useCounterUserRequestsGuest = create<CounterState>((set) => ({
  counterUserRequestsGuest: 0,
  setCounterUserRequestsGuest: (value: number) => set({ counterUserRequestsGuest: value }),
}));
