import { Button, Input,Row } from "antd"
import { FC } from "react"

//icons
import Search from '../../assets/images/icons/search.svg'
import ChatDots from '../../assets/images/icons/chat-dots.svg'
import Database from '../../assets/images/icons/database.svg'
import Bell from '../../assets/images/icons/bell.svg'
import ArrowDown from '../../assets/images/icons/arrow-down.svg'
import User from '../../assets/images/app/user.png'

import { grayColor,grayColor2,lightGray,lightGray2,whiteColor } from "src/constants/Colors"

const Header: FC<{ renderMenu?: FC }> = ({}) => {
  return (
    <div style={{ display: "flex", justifyContent: 'space-between'}}>
      <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
        <input
          style={{
            outline: 0,
            border: 0,
            fontSize: 16,
            padding: "0 14px 0 43px",
            borderWidth: 1,
            borderColor: lightGray,
            fontWeight: 200,
            height: 47,
            width: 425,
            borderRadius: 15,
            color: grayColor,
          }}
          placeholder='Search'
        />
        <img style={{ position: "absolute", left: 14 }} src={Search} alt='' />
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{display: 'flex', cursor: 'pointer'}}>
            <img src={ChatDots} alt=""/>
            <p style={{
              fontWeight: 200,
              fontSize: 16,
              color: grayColor2,
              marginLeft: 8,
            }}>Need help</p>
          </div>
          <Button style={{display: 'flex', marginLeft: 20, height: 44, alignItems: 'center', fontSize: 16}} type="primary" icon={<img src={Database}/>} title="Data export">
            Data export
          </Button>
          <div style={{marginLeft: 20, display: 'flex'}}>
            <div style={{width: 1, margin: '0 20px 0 20px', height: 26, backgroundColor: lightGray2}}></div>
            <img style={{cursor: 'pointer'}} src={Bell} alt="" />
            <div style={{width: 1, margin: '0 20px 0 20px', height: 26, backgroundColor: lightGray2}}></div>
          </div>
          <div>
            <img width={50} height={50} src={User} alt="" />
          </div>
          <div style={{marginLeft: 10, cursor: 'pointer'}}>
            <img src={ArrowDown} alt="" />
          </div>
      </div>
    </div>
  )
}

export { Header }
