import { create } from 'zustand';

interface CounterState {
  counterUserReviewed: number;
  setCounterUserReviewed: (value: number) => void;
}

export const useCounterUserReviewed = create<CounterState>((set) => ({
  counterUserReviewed: 0,
  setCounterUserReviewed: (value: number) => set({ counterUserReviewed: value }),
}));
