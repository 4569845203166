import { create } from 'zustand';

interface CounterState {
  counterUserRequests: number;
  setCounterUserRequests: (value: number) => void;
}

export const useCounterUserRequests = create<CounterState>((set) => ({
  counterUserRequests: 0,
  setCounterUserRequests: (value: number) => set({ counterUserRequests: value }),
}));
