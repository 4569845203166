import { create } from 'zustand';

interface CounterState {
  counterEventRequests: number;
  setCounterEventRequests: (value: number) => void;
}

export const useCounterEventRequests = create<CounterState>((set) => ({
  counterEventRequests: 0,
  setCounterEventRequests: (value: number) => set({ counterEventRequests: value }),
}));
