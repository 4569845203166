import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import App from "./components/app"
import './styles/fonts.css';
import './styles/reset.css';

const rootElement = document.querySelector("#root") as HTMLElement

createRoot(rootElement).render(
  <StrictMode>
    <App />
  </StrictMode>
)
