import { create } from 'zustand';

interface CounterState {
  counterUnregistretedUser: number;
  setCounterUnregistretedUser: (value: number) => void;
}

export const useCounterUserUnregistreted = create<CounterState>((set) => ({
    counterUnregistretedUser: 0,
    setCounterUnregistretedUser: (value: number) => set({ counterUnregistretedUser: value }),
}));
