import React, { useState } from 'react';
//images
import Logo from '../../assets/images/app/logo-white.png'
import LogoWhite from '../../assets/images/icons/logo-white.svg'
import ArrowLeft from '../../assets/images/icons/arrow-left.svg'
import ArrowRight from '../../assets/images/icons/arrow-right.svg'
import Chart from '../../assets/images/icons/chart.svg'
import UserAdd from '../../assets/images/icons/user-plus.svg'
import CalendarAdd from '../../assets/images/icons/calendar-add.svg'
import UsersGroup from '../../assets/images/icons/users-group.svg'
import Calendar from '../../assets/images/icons/calendar.svg'
import BillList from '../../assets/images/icons/bill-list.svg'
import Report from '../../assets/images/icons/danger-triangle.svg'
//colors
import { blackColor, blackColor2, orangeColor, whiteColor } from 'src/constants/Colors';
//navigate
import {useNavigate} from "react-router-dom";
import { ROUTES } from 'src/constants/routes';
import { useMenu } from 'src/MenuContext';
import { useCounterUserRequests } from 'src/store/counterUserRequests';
import { useCounterEventRequests } from 'src/store/counterEventRequests';
import { useCounterUserUnregistreted } from 'src/store/counterUnregistretedUsers';
import { useCounterUserReviewed } from 'src/store/counterUserReviewd';
import { useCounterEventReviewed } from 'src/store/counterEventReviwed';
import { useCounterUserRequestsGuest } from 'src/store/counterUserRequestsGuest';

const mockDataMenu = [
  {
    id: 0,
    title: 'Dashboard',
    icon: Chart,
    link: ROUTES.DASHBOARD
  }, 
  {
    id: 1,
    title: 'User Access Requests Host',
    icon: UserAdd,
    link: ROUTES.USERS,
  },
  {
    id: 2,
    title: 'User Access Requests Guest',
    icon: UserAdd,
    link: ROUTES.USERS_NEW_GUEST,
  },
  {
    id: 3,
    title: 'Unregistered Users',
    icon: UserAdd,
    link: ROUTES.UNREGISTERED_USERS,
  },
  {
    id: 4,
    title: 'New Event Submissions',
    icon: CalendarAdd,
    link: ROUTES.NEW_EVENT,
  },
  {
    id: 5,
    title: 'User Management',
    icon: UsersGroup,
    link: ROUTES.USER_MANAGEMENT,
  },
  {
    id: 6,
    title: 'Event Management',
    icon: Calendar,
    link: ROUTES.EVENT_MANAGEMENT,
  },
  {
    id: 7,
    title: 'Subscriptions',
    icon: BillList,
    link: ROUTES.SUBSCRIPTIONS,
  },
  {
    id: 8,
    title: 'Reports',
    icon: Report,
    link: ROUTES.REPORTS,
  }
]

const SideMenu: React.FC = () => {
  const [activeItem, setActiveItem] = useState<number>(0);
  const navigate = useNavigate();

  const { collapseMenu, toggleCollapseMenu } = useMenu();

  const counterUserRequests = useCounterUserRequests((i: any) => i?.counterUserRequests);
  const counterUserRequestsGuest = useCounterUserRequestsGuest((i: any) => i?.counterUserRequestsGuest);
  const counterEventRequests = useCounterEventRequests((i: any) => i?.counterEventRequests);
  const counterUnregistretedUser = useCounterUserUnregistreted((i: any) => i?.counterUnregistretedUser);
  const counterUserReviewed = useCounterUserReviewed((i: any) => i?.counterUserReviewed);
  const counterEventReviewed = useCounterEventReviewed((i: any) => i?.counterEventReviewed);
  
  const handleClickItem = (id: number, link: string) => {
    setActiveItem(id);
    navigate(link)
  }

  const handleCollapseMenu = () => {
    toggleCollapseMenu()
  }

  return (
    <div style={{
      position: 'sticky',
      top: 0,             
      minWidth: collapseMenu? 100: 350, 
      backgroundColor: blackColor,
      height: '100vh',
      padding: '46px 0',
      }}>
      <div style={{
        display: 'flex', 
        justifyContent: collapseMenu? 'center': 'space-between',
        alignItems: 'center',
        marginBottom: 66,
        padding: collapseMenu? 0: '0 38px',
        position: 'relative'
      }}>
        <img width={collapseMenu? 30: 180} height={30} src={collapseMenu? LogoWhite: Logo}/>
        <img onClick={handleCollapseMenu} style={{position: collapseMenu? 'absolute': 'relative', right: collapseMenu? 15: 0, cursor: 'pointer', marginLeft: collapseMenu? 8: 0}} width={12} height={12} src={collapseMenu? ArrowRight: ArrowLeft}/>
      </div>
      <div>
        <p style={{
          color: whiteColor, 
          marginBottom: 20,
          fontWeight: 200,
          marginLeft: collapseMenu? 0: 38,
          textAlign: collapseMenu? 'center': 'start',
        }}>Menu</p>
        {mockDataMenu.map(item => (
          <div onClick={() => handleClickItem(item.id, item.link)} key={item.id} style={{
            display: 'flex', 
            alignItems: 'center', 
            cursor: 'pointer',
            padding: collapseMenu? '7px 38px': '15px 38px',
            backgroundColor: item.id === activeItem? blackColor2: ''
            }}>
            <img width={20} height={collapseMenu? 36: 20} src={item.icon}/>
            {!collapseMenu && <p style={{color: whiteColor, fontSize: 16, fontWeight: 200, marginLeft: 16}}>{item.title}</p>}
            {(item.id === 1 || item.id === 3 || item.id === 2 || item.id === 4 || item.id === 5 || item.id === 6) && !collapseMenu &&
              <div style={{
                width: 22, 
                height: 22, 
                borderRadius: 22, 
                backgroundColor: orangeColor, 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                marginLeft: 5
                }}>
                {item.id === 1 && <p style={{color: whiteColor, fontSize: 10, fontWeight: 700}}>{counterUserRequests}</p>}
                {item.id === 2 && <p style={{color: whiteColor, fontSize: 10, fontWeight: 700}}>{counterUserRequestsGuest}</p>}
                {item.id === 4 && <p style={{color: whiteColor, fontSize: 10, fontWeight: 700}}>{counterEventRequests}</p>}
                {item.id === 3 && <p style={{color: whiteColor, fontSize: 10, fontWeight: 700}}>{counterUnregistretedUser}</p>}
                {item.id === 5 && <p style={{color: whiteColor, fontSize: 10, fontWeight: 700}}>{counterUserReviewed}</p>}
                {item.id === 6 && <p style={{color: whiteColor, fontSize: 10, fontWeight: 700}}>{counterEventReviewed}</p>}
              </div>
            }
          </div>
        ))}
      </div>
    </div>
  );
};

export default SideMenu;